import { render, staticRenderFns } from "./index.vue?vue&type=template&id=76b5165a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./index.vue?vue&type=style&index=1&id=76b5165a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b5165a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/var/www/qanoniah.com/components/common/Icon.vue').default})
